import { Box, Text, Card, Loader, MarketingPageLayout, MarketingPageLayoutContent, Page, WixDesignSystemProvider, Image, Layout, FeatureList, Button, Notification } from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import Goal from './goal.png';
import { ReactTagManager } from 'react-gtm-ts';
import React, { useEffect } from 'react';
import * as Icons from '@wix/wix-ui-icons-common';
import { useTranslation } from "react-i18next";
const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}


function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs)
  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')
  const [isUpgraded, setIsUpgraded] = React.useState(false);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: ""
    }
  });


  const isFree = instanceData?.instance?.isFree !== false;

  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, [])


  const BASE_URL = `https://certifiedcode.wixsite.com/goal/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
      .catch(() => {

      })
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setIsUpgradeBannerOpen(data.instance.isFree !== false);
      })
      .catch(() => {

      })
  }

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"6f64d1e5-5630-4542-a499-d70a971c6c85"}?appInstanceId=${(instanceData as any)?.['instance']?.['instanceId']}`

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=6f64d1e5-5630-4542-a499-d70a971c6c85&redirectUrl=https://certifiedcode.wixsite.com/goal/_functions/@certifiedcode/base-backend/auth`;
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text={t('loading')} />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
    <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
      <Notification.TextLabel>
        {t('upgradeMessage')}
      </Notification.TextLabel>
      <Notification.ActionButton
        onClick={() => {
          window.open(upgradeUrl, "_blank");
        }}
      >
        {t('upgrade-button')}
      </Notification.ActionButton>
      <Notification.CloseButton
        onClick={() => setIsUpgradeBannerOpen(false)}
      />
    </Notification>
    <Card>
      <MarketingPageLayout
        removeImageHorizontalPadding
        removeImageVerticalPadding
        content={
          <Box height="840px" verticalAlign="middle">
            <MarketingPageLayoutContent
              title={t('goal-title')}
              content={t('goal-content')}
              actions={<Box direction="horizontal" gap="SP1">
                <Button skin="premium" disabled={isUpgraded} prefixIcon={<Icons.PremiumFilled />}
                  onClick={() => {
                    window.open(`${upgradeUrl}`)
                  }}
                >{t('goal-upgrade-button')}</Button>
                <Button skin='inverted' as='a' href='https://bridget.reviews.certifiedcode.us/6f64d1e5-5630-4542-a499-d70a971c6c85' target='_blank'>{t('rate-button')}</Button></Box>}
            />
          </Box>
        }
        image={<Image borderRadius={0} src={Goal} />}
      />
    </Card>
  </WixDesignSystemProvider >
}

export default App;
